import axios from "axios";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

// Redirect user to Spotify login
const connectToSpotify = ({ userId, bandId, redirectTo }) => {
  const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
  window.location.replace(
    `${baseUrl}/api/spotify/data?${conditionalString}&redirectTo=${encodeURIComponent(
      redirectTo
    )}`
  );
};

// Fetch Spotify user data after login
const getSpotifyUserData = async ({ userId, bandId }) => {
  try {
    const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
    const urlToQuery = `${baseUrl}/api/spotify/data?${conditionalString}`;
    const axiosResponse = await axios.get(urlToQuery);
    return axiosResponse.data;
  } catch (err) {
    console.error("Error fetching Spotify user data:", err);
    return false;
  }
};

const doesSpotifyTokenExist = async ({ userId, bandId }) => {
  try {
    const conditionalString = bandId ? `bandId=${bandId}` : `userId=${userId}`;
    const urlToQuery = `${baseUrl}/spotify/access-token-exists?${conditionalString}`;
    const axiosResponse = await axios.get(urlToQuery);
    return axiosResponse.data.tokenExists;
  } catch (err) {
    console.error("Error fetching Spotify token:", err);
    return false;
  }
};

export { connectToSpotify, getSpotifyUserData, doesSpotifyTokenExist };
